<template>
	<div class="box">
		<div class="mi-orderlist">
			<div class="container ">
				<div class="row" style="display: flex;">
					<div class="span4" ref="J_siteUserMenu">
						<div class="user-address-menu" id="J_userMenu">
							<div class="menu-box" v-for="(item,index) in FootNavMenuData" :key="index">
								<h3 class="title">{{ item.Name }}</h3>
								<ul class="list">
									<li v-for="(itclass,classindex) in item.ChildrenList" :key="classindex"
										:class="[routerIndex==itclass.Id?'menuActive':'']">
										<router-link  :to="{ path: itclass.linkUrl }" @click.native="flushCom">
											{{ itclass.Name }}
										</router-link>
									</li>
								</ul>
							</div>
						</div>

					</div>
					<div class="span16">
						<router-view></router-view>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				routerIndex: null,
			};
		},
		created() {
			this.routerIndex = this.$route.query.id
			this.loadFootNavMenuList()
		},
		methods: {
			flushCom() {
				this.$router.go(0)
			}
		}
	};
</script>
<style scoped>
	.menuActive a {
		color: #603c1d !important;
	}

	.uc-main-box {
		padding: 36px 0;
	}

	.mi-orderlist {
		background: #f5f5f5;
		padding-bottom: 40px;
	}

	.container {
		width: 1440px;
		margin-right: auto;
		margin-left: auto;
	}

	.container:after,
	.container:before {
		content: " ";
		display: table;
	}

	.row {
		
	}

	.row:after,
	.row:before {
		content: " ";
		display: table;
	}

	.span4 {
		width: 234px;
		flex-shrink: 0;
	}

	.user-address-menu {
		background: #fff;
		padding: 36px 0;
	}

	.user-address-menu .menu-box {
		margin: 0 48px 12px;
	}

	.user-address-menu h3 {
		font-size: 16px;
		font-weight: 400;
		line-height: 52px;
		color: #333;
		margin: 0;
		padding: 0;
	}

	.user-address-menu ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.user-address-menu li {
		padding: 6px 0;
		font-size: 14px;
	}

	.user-address-menu .active a,
	.user-address-menu .active a:hover {
		color: #ff6700;
	}

	.user-address-menu li a {
		color: #757575;
	}

	.span16 {
		margin-left: 16px;
		width: 100%;
	}
</style>
